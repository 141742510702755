import {
  Component,
  Input,
  ElementRef,
  ViewChild,
  ChangeDetectionStrategy,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormDialogCloseReason } from '@desquare/enums';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  standalone: true,
  selector: 'app-form-dialog',
  imports: [CommonModule, TranslateModule],
  template: `
    <div class="modal-container">
      <div class="modal-header">
        <h3>{{ headerText | translate }}</h3>
        <button
          type="button"
          class="btn-close btn-close-white"
          data-dismiss="modal"
          (click)="modal.dismiss()"
        ></button>
      </div>
      <div class="modal-body">
        <ng-container #bodyWrapper>
          <ng-content></ng-content>
        </ng-container>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-primary"
          *ngIf="showBackButton"
          (click)="back()"
        >
          {{ backButtonText | translate }}
        </button>

        <div class="push-right">
          <button
            type="button"
            class="btn btn-outline-warning me-2"
            [disabled]="dismissButtonEnabled"
            (click)="dismiss()"
          >
            {{ dismissButtonText | translate }}
          </button>
          <button
            type="button"
            class="btn btn-outline-danger me-2"
            *ngIf="showDeleteButton"
            (click)="delete()"
          >
            {{ deleteButtonText | translate }}
          </button>
          <button
            type="button"
            *ngIf="showExtraButton"
            class="btn btn-success me-2"
            (click)="extra()"
          >
            {{ extraButtonText | translate }}
          </button>

          <button
            [disabled]="!valid"
            type="button"
            [class.btn-outline-danger]="useDangerButton && pristine"
            [class.btn-outline-success]="!useDangerButton && pristine"
            [class.btn-danger]="useDangerButton && !pristine"
            [class.btn-success]="!useDangerButton && !pristine"
            class="btn"
            (click)="accept()"
          >
            {{ closeButtonText | translate }}
          </button>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      .push-right {
        margin-left: auto;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormDialogComponent {
  @ViewChild('bodyWrapper', {
    static: true,
  })
  bodyWrapper?: ElementRef;

  @Input() useDangerButton = false;
  @Input() dismissButtonText = 'NO';
  @Input() dismissButtonEnabled = false;
  @Input() deleteButtonText = 'DELETE';
  @Input() showDeleteButton = false;
  @Input() closeButtonText = 'YES';
  @Input() headerText = 'FORM_DIALOG_HEADER';
  @Input() pristine = true;
  @Input() valid = false;
  @Input() backButtonText = 'BACK';
  @Input() showBackButton = false;
  @Input() extraButtonText = 'EXT';
  @Input() showExtraButton = false;

  // onSubmit can be any object from a form
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() values: any = {};

  constructor(public modal: NgbActiveModal) {}

  accept() {
    this.modal.close(this.values);
  }

  dismiss() {
    this.modal.dismiss(this.values);
  }

  delete() {
    this.modal.close(FormDialogCloseReason.DELETE);
  }

  back() {
    this.modal.close(FormDialogCloseReason.BACK);
  }

  extra() {
    this.modal.close(FormDialogCloseReason.EXTRA);
  }
}
